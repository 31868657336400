import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { getUserTeamList } from '@/services/user_team';
import type { ConfigPrivilegeDetail } from '@/services/user_team/type';
import { Flex, Modal, ModalProps, Transfer } from '@antd';
import { useBoolean, useCreation, useMemoizedFn, useRequest } from 'ahooks';
import { intersection } from 'lodash';
import React, { useState } from 'react';
import { Func1 } from 'redux';

type UserGroupPickModalProps = {
  onUserGroupSelectOk?: (groups: AnyObject[], userId: number) => void;
} & ModalProps;

const filterOption = (inputValue: string, option) => option.title.indexOf(inputValue) > -1;
export const useUserGroupPickModal = (): [React.FC<UserGroupPickModalProps>, Func1<number, void>] => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [userGrpupIds, setUserGroupIds] = useState<Key[]>();
  const { user } = useAppContext();

  const { data: dataSource } = useRequest(
    async () => {
      const list = await getUserTeamList(user?.bid);
      return list?.map((v: ConfigPrivilegeDetail) => ({
        key: v.id,
        title: v.teamName,
        description: v.remark,
      }));
    },
    { refreshDeps: [user] },
  );

  const UserGroupPickModal = useMemoizedFn(({ children, onUserGroupSelectOk, ...params }) => {
    const allIds = useCreation(() => dataSource?.map((v) => v.key), [dataSource]);

    return (
      <Modal
        title={dict('SELECT_NAME', { name: dict('USER_GROUP') })} //选择用户组
        open={isModalOpen}
        onCancel={hideModal}
        destroyOnClose
        onOk={() => {
          onUserGroupSelectOk?.(userGrpupIds?.map(Number));
          hideModal();
        }}
        width={600}
        {...params}
      >
        <Flex justify="center">
          <Transfer
            dataSource={dataSource}
            showSearch
            filterOption={filterOption}
            listStyle={{
              width: 250,
              height: 300,
            }}
            targetKeys={intersection(userGrpupIds, allIds) as string[]}
            render={(item) => item.title}
            onChange={(nextTargetKeys: string[]) => setUserGroupIds(nextTargetKeys)}
          />
        </Flex>
      </Modal>
    );
  });

  const handleRecord = (userGrpupIds) => {
    setUserGroupIds(userGrpupIds);
    showModal();
  };

  return [UserGroupPickModal, handleRecord];
};
