import { useBoolean, useCreation, useRequest } from 'ahooks';
import { App, Button, Drawer, Dropdown, Flex, Input, InputNumber, MenuProps, Select, Tag } from '@antd';
import { clone, get, isArray, isEmpty, isNil } from 'lodash';
import { dict } from '@/hooks/useChangeLocale';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { defalutFormInputStyle } from '@/constants/const';
import { Edit, ThreePoint } from '@/icons';
import style from './style.less';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useEventEmitter } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import {
  AutoRoleConfigItemRowProps,
  AutoRuleConfigDrawerProps,
  AutoRuleConfigItem,
  AutoRuleOpEnum,
  AutoRuleProps,
  AutoRuleValue,
  AutoRuleValueEnum,
  AutoRuleValueItemProps,
  ManualRuleInputProps,
  ManualRuleItem,
  ManualRuleProps,
  Option,
} from './type';
import { getTagRuleList } from '@/services/tag/indext';
import { Validator } from '@/utils/validator';
import { ArrLabelText } from '@/components/Form/Field/Text';
import { getTempId, removePunctuation } from '../../const.var';

const ManualRuleInput = (props: ManualRuleInputProps) => {
  const { readonly, item, onChange, configRules, onRemove, event$ } = props;
  const [edited, { setTrue: setEdited }] = useBoolean(false);
  const ref = useRef<HTMLInputElement>();

  event$.useSubscription(({ action, value: id }) => {
    if (action == 'edit' && item?.id == id) {
      ref?.current?.focus();
    }
  });

  const { status, msg } = useCreation(() => {
    let status = '',
      msg = '';
    if (edited && !item?.valueName) {
      status = 'error';
      msg = dict('NAME_REQUIRED', { name: dict('TAG_NAME') });
    } else if (
      edited &&
      configRules
        .filter((v) => v.id != item?.id)
        .map((v) => v.valueName)
        .includes(item.valueName)
    ) {
      status = 'error';
      msg = dict('NAME_EXISTED', { name: dict('TAG_NAME') });
    }
    return {
      status,
      msg,
    };
  }, [edited, item.valueName, configRules]);

  return (
    <>
      <Input
        type="text"
        ref={ref as any}
        placeholder={dict('COUNT_CN_EN_NUMBER_UNLINE_SYMBOL_TEXT_REQUIRD_TIP', { count: 30 })}
        readOnly={readonly}
        style={{ ...defalutFormInputStyle, marginBottom: 10 }}
        value={item.valueName}
        status={status as 'error'}
        maxLength={30}
        onChange={(e) => {
          setEdited();
          onChange?.({ ...item, valueName: removePunctuation(e.target.value) });
        }}
        addonAfter={
          <Button
            type="link"
            size="small"
            icon={<DeleteOutlined />}
            onClick={onRemove}
            disabled={configRules?.length <= 1 || readonly}
          />
        }
      />
      {msg && <div style={{ color: 'red', marginTop: -10 }}>{msg}</div>}
    </>
  );
};

/**
 * 手动规则配置
 * @param param0
 * @returns
 */
export const ManualRule = ({ value, onChange, readonly }: ManualRuleProps) => {
  const configRules = useCreation(
    () => (isNil(value) ? [getManualRuleValue()] : value).map((item) => ({ ...item, id: item.id || getTempId() })),
    [value],
  ) as ManualRuleItem[];

  const updateConfigRules = (index, value) => {
    const newConfigRules = [...configRules];
    newConfigRules[index] = value;
    onChange?.(newConfigRules);
  };

  const event$: EventEmitter<EmitterObject> = useEventEmitter(); //添加菜单时通信事件

  const hasEmptyValue = configRules.some((v) => !v.valueName);

  return (
    <>
      {readonly
        ? configRules.map((v, index) => <Tag key={index}>{v.valueName}</Tag>)
        : configRules?.map((item, index) => {
            return (
              <ManualRuleInput
                key={item.id}
                event$={event$}
                onChange={(value) => updateConfigRules(index, value)}
                onRemove={() => onChange?.(configRules.filter((_, i) => i != index))}
                configRules={configRules}
                item={item}
              />
            );
          })}

      {!readonly && !hasEmptyValue && (
        <>
          <br />
          <Button
            type="dashed"
            hidden={readonly}
            onClick={() => {
              const newValue = getManualRuleValue();
              onChange?.([...configRules, newValue]);
              setTimeout(() => event$.emit({ action: 'edit', value: newValue.id }));
            }}
            icon={<PlusOutlined />}
          >
            {dict('ACTION_ADD')}
          </Button>
        </>
      )}
    </>
  );
};

/**
 * 标签值
 * @returns
 */
const AutoRuleValueItem = ({
  value,
  readonly,
  onDelete,
  onChange,
  onChecked,
  checked,
  event$,
  allValue,
}: AutoRuleValueItemProps) => {
  const [editable, { setTrue: setEditable, setFalse: setUneditable }] = useBoolean(false); //是否可编辑
  const [edited, { setTrue: setEdited }] = useBoolean(false); //是否编辑过
  const [name, setName] = useState(value.valueName); //标签名

  const ref = useRef<HTMLInputElement>();

  const onCheckedItemAndFocus = () => {
    setEditable();
    onChecked?.(value.id);
    setTimeout(() => ref?.current?.focus());
  };

  const items: MenuProps['items'] = [
    {
      key: 'rename',
      label: dict('ACTION_RENAME'),
      onClick: onCheckedItemAndFocus,
    },
    {
      key: 'delete',
      label: dict('ACTION_DELETE'),
      disabled: allValue.length <= 1,
      onClick: (e) => {
        e.domEvent.stopPropagation(); // 阻止事件冒泡
        onDelete?.(value.id);
      },
    },
  ];

  event$.useSubscription(({ action, value: id }) => {
    if (action == 'edit' && value?.id == id) {
      onCheckedItemAndFocus();
    }
  });

  const checkResult = useCreation(() => {
    if (editable && checked) {
      let status = '';
      let msg = '';
      if (!name && edited) {
        status = 'error';
        msg = dict('NAME_REQUIRED', { name: dict('TAG_NAME') });
      } else if (
        name &&
        allValue
          ?.filter((v) => v.id != value?.id)
          .map((v) => v.valueName)
          .includes(name)
      ) {
        status = 'error';
        msg = dict('NAME_EXISTED', { name: dict('TAG_NAME') });
      }

      return { status, msg, edited };
    }
    return null;
  }, [editable, checked, name, value?.id, allValue]);

  return (
    <div
      onClick={() => {
        onChecked?.(value.id);
      }}
    >
      {editable && checked ? (
        <div style={{ margin: 5 }}>
          <Input
            size="middle"
            value={name}
            status={checkResult?.status as 'error'}
            ref={ref as any}
            onChange={(e) => {
              setEdited();
              setName(removePunctuation(e.currentTarget.value));
            }}
            onBlur={() => {
              setUneditable();
              onChange?.({ ...value, valueName: checkResult?.msg ? '' : name });
            }}
            maxLength={30}
            showCount
          />
          {checkResult?.msg && <div style={{ color: 'red' }}>{checkResult?.msg}</div>}
        </div>
      ) : (
        <div className={classNames(style['tag-value-view'], { selected: checked })}>
          <div className="ellipsis-text" title={value?.valueName}>
            {value?.valueName || dict('UNKNOWN_NAME')}
          </div>
          <div style={{ display: readonly ? 'none' : 'block' }}>
            <Dropdown menu={{ items }}>
              <ThreePoint />
            </Dropdown>
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * 抽屉自动规则配置
 * @returns
 */
const AutoRoleConfigItemRow = ({
  value,
  onDelete,
  onChange,
  ruleObjOptions,
  ruleObjeMapping,
}: AutoRoleConfigItemRowProps) => {
  const { enums, attributeType, optList } = useCreation(
    () => (value.key ? ruleObjeMapping[value.key] : {}),
    [value.key],
  );

  return (
    <Flex justify="space-between" style={{ margin: '10px 0' }} gap={5}>
      <Select
        style={{ width: 150 }}
        placeholder={dict('PLEASE_SELECT')}
        value={value.key}
        onChange={(value) => {
          onChange?.({ key: value, opt: undefined, value: undefined });
        }}
        options={ruleObjOptions}
      />
      <Select
        style={{ width: 150 }}
        placeholder={dict('PLEASE_SELECT')}
        value={value.opt}
        onChange={(value) => onChange?.({ opt: value, value: undefined })}
        options={optList?.map((v) => ({ label: v.optName, value: v.optCode }))}
      />
      {(attributeType == AutoRuleValueEnum.STRING || !attributeType) && (
        <Input
          style={{ width: 200 }}
          value={get(value.value, '[0]', '')}
          maxLength={30}
          onChange={(e) =>
            onChange?.({ value: removePunctuation(e.target.value) ? [removePunctuation(e.target.value)] : [] })
          }
          placeholder={dict('PLEASE_TYPE')}
        />
      )}

      {attributeType == AutoRuleValueEnum.INTEGER && (
        <InputNumber
          style={{ width: 200 }}
          value={get(value.value, '[0]', '')}
          onChange={(value) => onChange?.({ value: value ? [value] : [] })}
          placeholder={dict('PLEASE_TYPE')}
        />
      )}

      {attributeType == AutoRuleValueEnum.ENUM && value.opt == AutoRuleOpEnum.MULTIPLE && (
        <Select
          style={{ width: 200 }}
          value={value.value}
          mode="multiple"
          options={enums.map((v) => ({ label: v, value: v }))}
          onChange={(value) => onChange?.({ value })}
          placeholder={dict('PLEASE_SELECT')}
        />
      )}

      {attributeType == AutoRuleValueEnum.ENUM && value.opt != AutoRuleOpEnum.MULTIPLE && (
        <Select
          style={{ width: 200 }}
          value={get(value.value, '[0]', '')}
          options={enums.map((v) => ({ label: v, value: v }))}
          onChange={(value) => onChange?.({ value: value ? [value] : [] })}
          placeholder={dict('PLEASE_SELECT')}
        />
      )}

      <Button
        type="default"
        style={{ border: 0 }}
        icon={<DeleteOutlined />}
        onClick={() => onDelete(value.id as string)}
      />
    </Flex>
  );
};

export const getManualRuleValue = () => ({ id: getTempId(), valueName: '' });

export const getAutoRuleTagValue = (): AutoRuleValue => {
  return {
    id: getTempId(),
    valueName: '',
    rule: [],
  };
};

export const AutoRule = ({ value, onChange, tagObject, readonly }: AutoRuleProps) => {
  const [drawerVisible, { setTrue: showDrawer, setFalse: hideDrawer }] = useBoolean(false);
  const [isEditing, { setTrue: setEditing, setFalse: cancelEditing }] = useBoolean(false);

  const event$: EventEmitter<EmitterObject> = useEventEmitter(); //添加菜单时通信事件
  const [selectItemId, setSelectItemId] = useState<Key>(); //选中的item
  const tagValues = useCreation(
    () => (isNil(value) ? [getAutoRuleTagValue()] : value.map((v) => ({ ...v, id: v.id || getTempId() }))),
    [value],
  ) as AutoRuleValue[];

  const currTagObject = useCreation(
    () => tagValues?.find((item) => item.id == selectItemId),
    [selectItemId, tagValues],
  ) as AutoRuleValue; //当前操作的对象

  const [opRuleSet, setOpRuleSet] = useState<AutoRuleConfigItem[]>(); //当前配置的规则对象里的AND条件
  const [opRuleRowIndex, setOpRuleRowIndex] = useState<number>(); //当前配置的规则对象里的index

  //默认设置第一个选中
  useEffect(() => {
    if (isEmpty(tagValues)) return;
    if (!selectItemId) {
      setSelectItemId(tagValues?.[0].id);
    }
  }, [tagValues, selectItemId]);

  //加载配置规则
  const { data: ruleSet } = useRequest(async () => (tagObject ? getTagRuleList(tagObject) : []), {
    refreshDeps: [tagObject],
  });

  //规则数据结构转换
  const { ruleObjOptions, ruleObjMapping } = useCreation(() => {
    const ruleObjOptions: Option[] = [];
    const ruleObjMapping = {};
    ruleSet?.forEach((v) => {
      const { attributeCode, attributeType, attributeName, enums, optList } = v;
      ruleObjOptions.push({ label: attributeName, value: attributeCode });
      ruleObjMapping[attributeCode] = { enums, attributeType, optList };
    });
    return { ruleObjOptions, ruleObjMapping };
  }, [ruleSet]);

  return (
    <>
      <div className={style['auto-rule-panel']}>
        <div className={style['rule-panel-left']}>
          <div className={style['header']}>{dict('TAG_VALUE')}</div>
          <div className="wrap">
            {tagValues?.map((item) => {
              return (
                <AutoRuleValueItem
                  value={item}
                  key={item.id}
                  allValue={tagValues}
                  checked={item.id == selectItemId}
                  onChecked={setSelectItemId}
                  event$={event$}
                  readonly={readonly}
                  onDelete={(id) => {
                    const newTagValues = tagValues.filter((item) => item.id != id);
                    onChange?.(newTagValues);
                    if (id == selectItemId) {
                      setSelectItemId(undefined);
                      cancelEditing();
                    }
                  }}
                  onChange={(result) => {
                    cancelEditing();
                    const newTagValues = result.valueName
                      ? tagValues.map((item) => {
                          if (item.id == selectItemId) {
                            return result;
                          }
                          return item;
                        })
                      : tagValues.filter((item) => item.id != result.id);
                    if (newTagValues.length) onChange?.(newTagValues);
                  }}
                />
              );
            })}
          </div>
          {!isEditing && !readonly && (
            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={() => {
                const tag = getAutoRuleTagValue();
                onChange?.([...tagValues, tag]);
                setSelectItemId(tag.id);
                setTimeout(() => {
                  event$.emit({ action: 'edit', value: tag.id });
                  setEditing();
                });
              }}
            >
              {dict('ACTION_ADD')}
            </Button>
          )}
        </div>
        <div className={style['rule-panel-right']}>
          <div className={style['header']}>{dict('TAG_SETTING_RULE')}</div>
          <p style={{ paddingLeft: 10 }}>{dict('AUTO_RULE_CONFING_TIP')}</p>

          {currTagObject?.rule?.map((items, index) => {
            const borderSytle = '1px solid #efefef';
            return (
              <div key={`${index}_${JSON.stringify(items)}`}>
                {index > 0 && <div style={{ marginLeft: 18 }}>{dict('OR')}</div>}
                <div style={{ margin: 10, display: 'flex' }}>
                  <div style={{ border: borderSytle, flex: 1, display: 'flex' }}>
                    {items.length > 1 && (
                      <div
                        style={{
                          width: 30,
                          borderRight: borderSytle,
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        {dict('AND')}
                      </div>
                    )}
                    <div style={{ flex: 1, padding: 5 }}>
                      {items.map((item) => {
                        const { optList } = ruleObjMapping[`${item.key}`] || {};
                        return (
                          <div key={item.id || JSON.stringify(item)} style={{ display: 'flex', gap: 10, margin: 10 }}>
                            <ArrLabelText list={ruleObjOptions} value={item.key} />
                            <ArrLabelText
                              style={{ color: '#777' }}
                              list={
                                optList?.map((v) => ({
                                  label: v.optName,
                                  value: v.optCode,
                                })) || []
                              }
                              value={item.opt}
                            />
                            <div style={{ maxWidth: '60%', wordBreak: 'break-word' }}>
                              {isArray(item.value) ? item.value.join(',') : item.value}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {!readonly && (
                    <div style={{ width: 20, display: 'flex', flexDirection: 'column', justifyItems: 'center' }}>
                      <Button
                        type="link"
                        size="small"
                        icon={<Edit className="action-icon" />}
                        onClick={() => {
                          setOpRuleSet(currTagObject.rule[index]);
                          setOpRuleRowIndex(index);
                          showDrawer();
                        }}
                      />
                      <Button
                        type="link"
                        size="small"
                        icon={<DeleteOutlined className="action-icon" />}
                        onClick={() => {
                          currTagObject.rule.splice(index, 1);
                          onChange?.([...tagValues]);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          {!readonly && (
            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={() => {
                setOpRuleSet([]);
                setOpRuleRowIndex(currTagObject?.rule.length || 0);
                showDrawer();
              }}
            >
              {dict('CONDITION')}
            </Button>
          )}
        </div>
      </div>
      {!readonly && (
        <AutoRuleConfigDrawer
          visible={drawerVisible}
          ruleSet={opRuleSet}
          ruleObjOptions={ruleObjOptions}
          ruleObjeMapping={ruleObjMapping}
          hideDrawer={hideDrawer}
          onChange={(ruleSet) => {
            currTagObject.rule[opRuleRowIndex || 0] = ruleSet;
            onChange?.([...tagValues]);
          }}
        />
      )}
    </>
  );
};

/**
 * 抽屉配置部分
 * @param props
 * @returns
 */
const AutoRuleConfigDrawer = (props: AutoRuleConfigDrawerProps) => {
  const { visible, ruleSet, hideDrawer, ruleObjOptions, ruleObjeMapping } = props;
  const [rules, setRules] = useState<AutoRuleConfigItem[]>([]);
  const { message } = App.useApp();

  useEffect(() => setRules(clone(ruleSet || []).map((v) => ({ ...v, id: v.id || getTempId() }))), [ruleSet]);

  return (
    <Drawer
      title={dict('RULE_CONFIG')}
      open={visible}
      width={500}
      onClose={hideDrawer}
      footer={
        <Flex justify="right" gap={10}>
          <Button onClick={hideDrawer}>{dict('ACTION_CANCEL')}</Button>
          <Button
            onClick={() => {
              const checkConfigError = rules.some((v) => {
                const attr = Object.values(v);
                return attr.length < 4 || attr.some((v) => Validator.isNilEmpty(v));
              });
              if (checkConfigError) {
                message.warning(dict('CHECK_RULE_CONFIG_ERROR_TIP'));
              } else {
                hideDrawer();
                props.onChange?.(rules);
              }
            }}
            type="primary"
          >
            {dict('ACTION_CONFIRM')}
          </Button>
        </Flex>
      }
    >
      <p style={{ marginTop: -10 }}>{dict('AUTO_RULE_CONFIG_TIP')}</p>
      {rules.map((v, index) => (
        <AutoRoleConfigItemRow
          key={v.id}
          value={v}
          ruleObjOptions={ruleObjOptions}
          ruleObjeMapping={ruleObjeMapping}
          onChange={(obj) => {
            const newV = { ...v, ...obj };
            rules[index] = newV;
            setRules([...rules]);
          }}
          onDelete={(id) => {
            setRules(rules.filter((item) => item.id !== id));
          }}
        />
      ))}
      <Button
        type="link"
        icon={<PlusOutlined />}
        onClick={() => {
          rules.push({ id: getTempId() });
          setRules([...rules]);
        }}
      >
        {dict('ACTION_ADD')}
      </Button>
    </Drawer>
  );
};
