import { dict } from '@/hooks/useChangeLocale';
import { Image, Typography } from '@antd';

const { Title } = Typography;
export const DoiuyinAccountPictureTip = () => {
  return (
    <div>
      <Title level={5} style={{ margin: '0 12px' }}>
        {dict('DOUYIN_NO_COPY_ITP')}
      </Title>
      <Image src={`${BASE_PATH}/images/douyin-tip.png`} />
    </div>
  );
};
