import { Blank, FormCard } from '@/components/PageCard';
import { ROUTE_PATH, defaultFormItemConfig } from '@/constants/const';
import { FormOpType } from '@/constants/enum';
import { App, Button, Flex, Form, Input } from '@antd';
import React from 'react';
import UserConfig from '../../components/fields/UserSet';
import { REGEX } from '@/constants/regex';
import { checkUserTeamName, createUserTeam, updateUserTeam } from '@/services/user_team';
import { get } from 'lodash';
import { useRequest } from 'ahooks';
import { useNavigate } from '@/umi';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';

type FormContentProps = {
  opType: FormOpType;
  initialData?: AnyObject;
};

const FormContent: React.FC<FormContentProps> = ({ opType, initialData }) => {
  const { message } = App.useApp();
  const { user } = useAppContext();
  const navigate = useNavigate();

  const back = () => navigate(opType == FormOpType.CREATE ? './..' : './../..');

  const [form] = Form.useForm();

  const { runAsync: submit, loading: submitting } = useRequest(
    async (configPrivilege = false) => {
      try {
        const values = await form.validateFields();
        const submitAction = opType == FormOpType.CREATE ? createUserTeam : updateUserTeam;
        const id = await submitAction(values);
        message.success(dict('OPERATION_SUCCESS'));
        configPrivilege
          ? navigate(
              `/${ROUTE_PATH.SYSTEM}/${ROUTE_PATH.PERMISSION}/${ROUTE_PATH.USER_GROUP}/${ROUTE_PATH.CONFIG}/${
                initialData?.id || id
              }`,
            )
          : back();
      } catch (error) {
        if (get(error, 'errorFields')) {
          form.scrollToField(get(error, 'errorFields[0].name[0]', ''));
        } else {
          message.error(get(error, 'status.message', dict('OPERATION_FAILURE')));
        }
      }
    },
    { manual: true },
  );

  return (
    <Form
      form={form}
      {...defaultFormItemConfig}
      validateTrigger={['onSubmit', 'onChange', 'onBlur']}
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 10 }}
      initialValues={initialData}
    >
      <FormCard title={dict('BASIC_INFO')}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item hidden name="bid" initialValue={user?.bid}>
          <Input />
        </Form.Item>
        <Form.Item
          label={dict('USERGROUP_NAME')}
          name="teamName"
          required
          rules={[
            { max: 50 },
            {
              validateTrigger: 'onBlur',
              async validator(_, name) {
                if (!name) return Promise.reject(dict('PLEASE_TYPE_NAME', { name: dict('USERGROUP_NAME') }));
                if (!REGEX.TEXT.test(name)) return Promise.reject(dict('SUPPORT_CN_EN_NUMBER_UNLINE_TEXT_TIP'));
                try {
                  const exist = await checkUserTeamName(name, initialData?.id, user?.bid);
                  if (exist) return Promise.reject(dict('NAME_EXISTED', { name: dict('USERGROUP_NAME') }));
                } catch (error) {
                  return Promise.reject(get(error, 'status.message', dict('VALIDATION_EXCEIPTION')));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder={dict('COUNT_CN_EN_NUMBER_UNLINE_TEXT_REQUIRD_TIP', { count: 50 })} />
        </Form.Item>

        <Form.Item label={dict('REMARK')} name="remark" rules={[{ max: 255 }]}>
          <Input.TextArea placeholder={dict('COUNT_CN_EN_NUMBER_UNLINE_SYMBOL_TEXT_REQUIRD_TIP', { count: 255 })} />
        </Form.Item>
      </FormCard>
      <Blank />

      <FormCard title={dict('GROUP_USERS')}>
        <Form.Item label={dict('USER_CONFIG')} name="userIdList">
          <UserConfig />
        </Form.Item>
      </FormCard>

      <Blank />
      <FormCard>
        <Flex gap={10} justify="center">
          <Button onClick={back}>{dict('ACTION_CANCEL')}</Button>
          <Button type="primary" loading={submitting} onClick={() => submit()}>
            {dict('ACTION_SAVE')}
          </Button>
          <Button type="primary" loading={submitting} onClick={() => submit(true)}>
            {dict('ACTION_SAVE_AND_CONFIG_PERMISSION')}
          </Button>
        </Flex>
      </FormCard>
    </Form>
  );
};

export default FormContent;
