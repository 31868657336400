import Text from '@/components/Form/Field/Text';
import { OrgTreeSelect } from '@/components/Form/Field/select/OrgTreeSelect';
import { FormOpType } from '@/constants/enum';
import { useAppContext } from '@/contexts/AppContext';
import { LocalesKeys } from '@/hooks/umi';
import { dict } from '@/hooks/useChangeLocale';
import { checkOrganization } from '@/services/organization';
import type { OrganizationPageParams } from '@/services/organization/type';
import { ModalForm } from '@ant-design/pro-components';
import { Form, Input } from '@antd';
import { useSafeState } from 'ahooks';
import { get } from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { OrgFieldDiffMapping, OrganizationEnum } from '../../const.var';
import type { OrgConfigType } from '../../const.var';

type FormModalProps = {
  onFinish?: (value) => Promise<AnyObject>;
  record?: AnyObject;
  children: ReactElement;
  orgType: OrganizationEnum;
  formType: FormOpType;
  disabled?: boolean;
};

const formTitleType = {
  [FormOpType.CREATE]: 'CREATE_NAME',
  [FormOpType.EDIT]: 'EDIT_NAME',
  [FormOpType.VIEW]: 'VIEW_NAME',
};

const FormModal: React.FC<FormModalProps> = ({ record, children, orgType, formType, disabled, onFinish, ...props }) => {
  const [form] = Form.useForm<OrganizationPageParams>();
  const [opened, setOpened] = useSafeState(false);
  const config: OrgConfigType = OrgFieldDiffMapping[orgType];
  const { user } = useAppContext();

  const isView = formType == FormOpType.VIEW;

  useEffect(() => {
    if (opened) form.setFieldsValue(record);
  }, [record, opened]);

  return disabled ? (
    children
  ) : (
    <ModalForm<OrganizationPageParams>
      title={dict(formTitleType[formType] as LocalesKeys, { name: config.title })}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 18 }}
      trigger={children}
      onOpenChange={setOpened}
      form={form}
      modalProps={{ cancelButtonProps: { hidden: isView }, destroyOnClose: true }}
      width={600}
      layout="horizontal"
      onFinish={async (values) => {
        if (isView) return true;
        return onFinish?.(values);
      }}
      {...props}
    >
      <Form.Item
        label={config.name.alias}
        name="name"
        required={!isView}
        rules={[
          { max: 50 },
          {
            validateTrigger: 'onBlur',
            async validator(_, name) {
              if (!name) return Promise.reject(dict('PLEASE_TYPE_NAME', { name: config.name.alias }));
              try {
                const exist = await checkOrganization({
                  id: record?.id,
                  value: name,
                  field: 'name',
                  bid: user?.bid,
                  // type: orgType,
                });
                if (exist) return Promise.reject(dict('ORG_NAME_EXISTED'));
              } catch (error) {
                return Promise.reject(get(error, 'status.message', dict('VALIDATION_EXCEIPTION')));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input placeholder={dict('INPUT_TEXT_VALIDATATION_TIP', { count: 50 })} disabled={isView} />
      </Form.Item>

      {config.code.show && (
        <Form.Item
          label={config.code.alias}
          name="code"
          required={!isView}
          rules={[
            { max: 50 },
            {
              validateTrigger: 'onBlur',
              async validator(_, code) {
                if (!code) return Promise.reject(dict('PLEASE_TYPE_NAME', { name: config.code.alias }));
                try {
                  const exist = await checkOrganization({
                    id: record?.id,
                    value: code,
                    field: 'code',
                    bid: user?.bid,
                    type: orgType as number,
                  });
                  if (exist) return Promise.reject(dict('ORG_NAME_EXISTED'));
                } catch (error) {
                  return Promise.reject(get(error, 'status.message', dict('VALIDATION_EXCEIPTION')));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder={dict('INPUT_TEXT_VALIDATATION_TIP', { count: 50 })} disabled={isView} />
        </Form.Item>
      )}

      {config.parentId.show && (
        <Form.Item
          label={dict('AFFILIATION_ORG')}
          name="parentId"
          required={!isView && config.parentId.required}
          rules={[{ required: !isView && config.parentId.required }]}
        >
          {isView ? (
            <span>
              <Text value={record?.treeName} />
            </span>
          ) : (
            <OrgTreeSelect
              placeholder={dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('AFFILIATION_ORG') })}
              treeDefaultExpandAll
              scope="authorize-account"
              allowOrgType={config.parentId.allowOrgType}
            />
          )}
        </Form.Item>
      )}

      {config.address.show && (
        <Form.Item label={dict('FULL_ADDRESS')} name="address">
          <Input.TextArea
            placeholder={dict('INPUT_TEXT_VALIDATATION_TIP', { count: 255 })}
            maxLength={255}
            showCount
            disabled={isView}
          />
        </Form.Item>
      )}
      <Form.Item label={dict('REMARK')} name="remark" rules={[{ max: 255 }]}>
        <Input.TextArea
          placeholder={dict('INPUT_TEXT_VALIDATATION_TIP', { count: 255 })}
          maxLength={255}
          showCount
          disabled={isView}
        />
      </Form.Item>
    </ModalForm>
  );
};

export default FormModal;
