import { dict } from '@/hooks/useChangeLocale';
import style from './index.less';

export const XiaoHongShuScanCodeWrap = (props) => {
  return (
    <div className={style['qr-panel']}>
      <div style={{ textAlign: 'center' }}>
        <h6>{dict('QUICK_ANS_SECURE_LOGIN')}</h6>
        <p>{dict('OPEN_APP_AND_SCAN_QR', { platform: dict('PLATFORM_XIHONGSHU') })}</p>
        {props.children}
      </div>
      <div className={style['scope-container']}>
        <div className={style['client-info']}>
          <span className={style['client-name']}>{dict('APP_NAME')}</span>
          <span>{dict('WILL_OBTAINE_FOLLOWING_PERMISSION')}</span>
        </div>
        <div className={style['scope-list-wrap']}>
          <div className={style['client-scope']}>
            {[
              dict('PLATFROM_QR_AUTH_TIP1'),
              dict('PLATFROM_QR_AUTH_TIP2', { platform: dict('PLATFORM_XIHONGSHU') }),
              dict('PLATFROM_QR_AUTH_TIP3', { platform: dict('PLATFORM_XIHONGSHU') }),
              dict('PLATFROM_QR_AUTH_TIP4', { platform: dict('PLATFORM_XIHONGSHU') }),
            ].map((v, index) => (
              <div className={style['scope-item']} key={index}>
                <div className={style['icon-scope']} />
                <span className={style['text-left']}>{v}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
