import { defaultFormItemConfig } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { Alert, App, Button, Form, Input, Modal, Select, Space, Table } from '@antd';
import { useBoolean, useCreation } from 'ahooks';
import { FormInstance } from 'antd/lib/form';
import { Function1, isEmpty, isUndefined, uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import style from './index.less';

const CUSTOM = 'custom';
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  dataIndex: string;
  record: AnyObject;
  index: number;
  children: React.ReactNode;
  readonly: boolean;
  updateRecord: Function1<AnyObject, void>;
}

const EditableCell: React.FC<EditableCellProps> = ({
  dataIndex,
  title,
  record,
  index,
  children,
  readonly,
  updateRecord,
  ...restProps
}) => {
  const { getConfigValue } = useAppContext();
  const keywordRule = getConfigValue('keywordRule');

  const { ruleTypeMapping, ruleTypeOption } = useCreation(() => {
    const ruleTypes = Object.entries(keywordRule).map(([name, expression]) => {
      return {
        key: name,
        name: name,
        expression,
      };
    });

    const ruleTypeMapping = ruleTypes.reduce((init, v) => {
      init[v.key] = { ...v };
      return init;
    }, {});

    const ruleTypeOption = [
      {
        label: dict('SYSTEM_RULE'),
        options: ruleTypes.map(({ name }) => ({
          label: name,
          value: name,
        })),
      },
      {
        label: dict('CUSTOM_RULE'),
        value: CUSTOM,
      },
    ];

    return {
      ruleTypeOption,
      ruleTypeMapping,
    };
  }, [keywordRule]);

  let comp = children;

  const disabled = record?.expressionKey != CUSTOM;

  if (readonly) {
    comp = children;
  } else if (dataIndex == 'expressionKey') {
    comp = (
      <Form.Item
        name={[index, dataIndex]}
        rules={[
          {
            required: true,
            validateTrigger: 'onSelect',
            message: dict('PLEASE_SELECTED_RULE'),
          },
        ]}
      >
        <Select
          placeholder={dict('PLEASE_SELECT')}
          options={ruleTypeOption}
          style={{ minWidth: 100 }}
          onChange={(key) => {
            if (key != CUSTOM) {
              const { name, expression } = ruleTypeMapping[key as string];
              updateRecord({ ...record, name, expression, [dataIndex]: key });
            } else {
              updateRecord({ ...record, name: '', expression: '', [dataIndex]: key });
            }
          }}
        />
      </Form.Item>
    );
  } else if (dataIndex == 'name') {
    comp = (
      <Form.Item
        name={[index, dataIndex]}
        rules={[
          {
            required: true,
            validateTrigger: 'onBlur',
            max: 50,
            message: dict('PLEASE_ENTER_RULE_NAME'),
          },
        ]}
      >
        <Input
          placeholder={dict('CUSTOM_RULE_NAME')}
          onBlur={(e) => updateRecord({ ...record, [dataIndex]: e.target.value })}
        />
      </Form.Item>
    );
  } else if (dataIndex == 'expression') {
    comp = (
      <Form.Item
        name={[index, dataIndex]}
        rules={[
          {
            required: true,
            validateTrigger: 'onSubmit',
            message: dict('PLEASE_ENTER_REGULAR_EXPRESSION'),
          },
        ]}
      >
        <ExpressionTextarea disabled={disabled} onChange={(value) => updateRecord({ ...record, [dataIndex]: value })} />
      </Form.Item>
    );
  }

  return (
    <td {...restProps} className={style['custom-rule-td']}>
      {comp}
    </td>
  );
};

type ExpressionTextareaProps = {
  value?: string;
  onChange?: (value) => void;
  disabled: boolean;
};
export const ExpressionTextarea: React.FC<ExpressionTextareaProps> = ({ value, onChange, disabled }) => {
  const { message } = App.useApp();

  //校验模框
  const [form] = Form.useForm();
  const [validateRuleModalVisible, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const validateRuleText = Form.useWatch('validateRuleText', form)?.trim();
  const expression = Form.useWatch('expression', form)?.trim();
  const [result, setResult] = useState<boolean>();

  const validateExpressionRule = () => {
    if (expression && validateRuleText) {
      setResult(new RegExp(expression).test(validateRuleText));
    } else {
      message.error(dict('CLASSIFICATION_AND_DATA_FILLED_TIP'));
    }
  };

  return (
    <>
      <Input.TextArea
        disabled={disabled}
        value={value}
        readOnly
        placeholder={dict('PLEASE_ENTER_REGULAR_EXPRESSION')}
        rows={2}
        onClick={() => {
          if (!disabled) showModal();
        }}
      />

      <Modal
        open={validateRuleModalVisible}
        title={dict('REGULAR_EXPRESSION_VERIFICATION')}
        destroyOnClose
        onCancel={hideModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={async () => {
          try {
            const { expression } = await form.validateFields();
            onChange?.(expression);
            hideModal();
          } catch (error) {}
        }}
      >
        <Form
          {...defaultFormItemConfig}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          validateTrigger="onSubmit"
          form={form}
        >
          <Form.Item
            label={dict('CLASSIFICATION_NAME')}
            required
            name="expression"
            initialValue={value}
            rules={[
              {
                validateTrigger: 'onSubmit',
                validator(_, value: string) {
                  if (!value) return Promise.reject(dict('PLEASE_ENTER_REGULAR_EXPRESSION'));
                  try {
                    new RegExp(value);
                    return Promise.resolve();
                  } catch (error) {
                    if (!value) return Promise.reject(dict('PLEASE_ENTER_REGULAR_EXPRESSION'));
                  }
                },
              },
            ]}
          >
            <Input.TextArea
              placeholder={dict('PLEASE_ENTER_REGULAR_EXPRESSION')}
              value={value}
              rows={2}
              onClick={(e) => {
                onChange?.(e.currentTarget.value);
              }}
            />
          </Form.Item>
          <Form.Item label={dict('SAMPLE_DATA')} name="validateRuleText">
            <Input.TextArea maxLength={255} showCount placeholder={dict('PLEASE_ENTER_SAMPEL_DATA')} />
          </Form.Item>
          <Form.Item label={dict('VERIFICATION_RESULT')}>
            <>
              {isUndefined(result) ? (
                <span>
                  <a onClick={validateExpressionRule}>{dict('CLICK_TO_VIEIFY')}</a>
                </span>
              ) : (
                <Space>
                  <Alert
                    showIcon
                    closable={false}
                    {...(result
                      ? {
                          type: 'success',
                          message: dict('PASS'),
                        }
                      : {
                          type: 'error',
                          message: dict('NOT_PASS'),
                        })}
                    style={{ backgroundColor: 'transparent', border: 0 }}
                  />
                  <a onClick={validateExpressionRule}>{dict('CLICK_TO_REVALIDATION')}</a>
                </Space>
              )}
            </>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export type DynamicRulesProps = {
  value?: AnyObject[];
  onChange?: Function1<AnyObject, void>;
  form?: FormInstance;
  readonly: boolean;
};

export const DynamicRules: React.FC<DynamicRulesProps> = ({ value, onChange, form, readonly }) => {
  const dataSource = useCreation(() => {
    return (value || []).map((item) => ({
      ...item,
      expressionKey: isUndefined(item.expressionKey) ? CUSTOM : item.expressionKey,
      _id: item._id || uniqueId(),
    }));
  }, [value]);

  useEffect(() => {
    if (isEmpty(dataSource)) onChange?.([{ _id: uniqueId(), expressionKey: null }]);
  }, [dataSource]);

  const getOnCell = (dataIndex) => (record, index) => ({
    record,
    dataIndex,
    index,
    readonly,
    updateRecord: (newRecord) => {
      dataSource[index] = newRecord;
      onChange?.([...dataSource]);
    },
  });

  useEffect(() => {
    form?.setFieldsValue(dataSource);
  }, [dataSource]);

  return (
    <>
      <Form form={form} component={false} validateTrigger={['onBlur', 'onSelect', 'onChange']}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          size="small"
          rowKey="_id"
          dataSource={dataSource}
          columns={
            [
              {
                title: dict('RULE_TYPE'),
                width: '20%',
                dataIndex: 'expressionKey',
                onCell: getOnCell('expressionKey'),
                render: (v) => (v == CUSTOM ? dict('CUSTOM_RULE') : v),
              },
              {
                title: dict('RULE_NAME'),
                width: '25%',
                dataIndex: 'name',
                onCell: getOnCell('name'),
              },
              {
                title: dict('REGULAR_EXPRESSION'),
                dataIndex: 'expression',
                onCell: getOnCell('expression'),
                width: '45%',
              },

              readonly
                ? false
                : {
                    title: dict('ACTION_OPERATE'),
                    align: 'center',
                    render: (_, record) => {
                      return (
                        <Button
                          type="link"
                          disabled={dataSource.length <= 1}
                          onClick={() => onChange?.(dataSource.filter((v) => v._id != record._id))}
                        >
                          {dict('ACTION_REMOVE')}
                        </Button>
                      );
                    },
                  },
            ].filter(Boolean) as []
          }
          pagination={false}
        />
      </Form>
      {!readonly && (
        <Space style={{ marginTop: 5 }}>
          <a
            onClick={() => {
              onChange?.([...dataSource, { _id: uniqueId(), expressionKey: null, name: '', expression: '' }]);
            }}
          >
            {dict('ACTION_ADD')}
          </a>
        </Space>
      )}
    </>
  );
};
