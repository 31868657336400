import { ROUTE_PATH } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import useTableRequest, { getColumnSearchProps, getTableColumn } from '@/hooks/useTableRequest';
import {
  deleteKeywordCategory,
  getKeywordCategoryList,
  updateKeywordCategoryStatus,
} from '@/services/keyword_category';
import { useNavigate } from '@/umi';
import { Format } from '@/utils/format';
import { App, Badge, Button, Space, Table, Typography } from '@antd';
import { useBoolean } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { get } from 'lodash';
import { KeywordStatus, KeywordUsedTypes, UsageScopeEnum } from '../const.var';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { dict } from '@/hooks/useChangeLocale';
import type { PresetStatusColorType } from 'antd/es/_util/colors';

type ListProps = {
  useScopeType?: UsageScopeEnum;
  event$: EventEmitter<string>;
};
const { Link } = Typography;
const List = ({ useScopeType, event$ }: ListProps) => {
  const { modal, message } = App.useApp();
  const { user } = useAppContext();
  const [loading, { setTrue: setLoading, setFalse: hideLoading }] = useBoolean();
  const navigate = useNavigate();

  const { tableProps, refresh } = useTableRequest(
    async ({ filters = {} }) => {
      const { name, disabled } = filters;
      const type = useScopeType == UsageScopeEnum.ALL ? undefined : useScopeType;
      const list = await getKeywordCategoryList({
        type,
        bid: user?.bid,
        name: get(name, '[0]'),
        disabled: get(disabled, '[0]'),
      });

      return { list, total: list.length };
    },
    { refreshDeps: [useScopeType, user?.bid], debounceWait: 100 },
    useScopeType,
  );

  const { getBtnPermissionConfig } = usePermission();

  return (
    <>
      <Space>
        <Button
          type="primary"
          size="middle"
          {...getBtnPermissionConfig(AUTH.SEMANTIC_CATEGORY.CREATE)}
          onClick={() => navigate(`./${ROUTE_PATH.CREATE}?type=${useScopeType}`)}
        >
          {dict('ACTION_NEW')}
        </Button>
      </Space>

      <Table
        style={{ marginTop: 10 }}
        {...tableProps}
        loading={loading || tableProps.loading}
        columns={[
          getTableColumn(
            {
              title: dict('CLASSIFICATION_NAME'), //分类名称
              align: 'left',
              dataIndex: 'name',
              ellipsis: true,
              width: 100,
              ...getColumnSearchProps({
                dataIndex: 'name',
              }),
            },
            useScopeType,
          ),
          getTableColumn({
            title: dict('SEMANTIC_CLASSIFICATION_TYPE'), //所属语义分类
            dataIndex: 'type',
            render: (v) => Format.toArrLabel(KeywordUsedTypes, v),
          }),
          getTableColumn({
            title: dict('INCLUDE_KEYWORDS'), //关联关键词
            dataIndex: 'includedKeywords',
            render: (_, record) =>
              get(record.includedKeywords, 'length', 0) + get(record.includedRules, 'length', 0) || '-',
          }),
          getTableColumn({
            title: dict('EXCLUDED_KEYWORDS'), //屏蔽关键词
            dataIndex: 'excludedKeywords',
            render: (_, record) =>
              get(record.excludedKeywords, 'length', 0) + get(record.excludedRules, 'length', 0) || '-',
          }),
          getTableColumn(
            {
              title: dict('STATUS'), //  状态,
              dataIndex: 'disabled',
              filters: KeywordStatus.map(({ label, value }) => ({ text: label, value })),
              filterMultiple: false,
              render: (v) => (
                <Badge
                  status={KeywordStatus.find((item) => item.value === v)?.status as PresetStatusColorType}
                  text={Format.toArrLabel(KeywordStatus, v)}
                />
              ),
            },
            useScopeType,
          ),
          getTableColumn({
            title: dict('ACTION_OPERATE'),
            align: 'center',
            render: (_, record) => {
              return (
                <Space>
                  <Link
                    {...getBtnPermissionConfig(AUTH.SEMANTIC_CATEGORY.VIEW)}
                    onClick={() => navigate(`./${ROUTE_PATH.DETAIL}/${record.id}?type=${useScopeType}`)}
                  >
                    {dict('ACTION_VIEW')}
                  </Link>
                  <Link
                    {...getBtnPermissionConfig(AUTH.SEMANTIC_CATEGORY.EDIT)}
                    onClick={() => navigate(`./${ROUTE_PATH.EDIT}/${record.id}?type=${useScopeType}`)}
                  >
                    {dict('ACTION_EDIT')}
                  </Link>

                  {!record.disabled ? (
                    <Link
                      {...getBtnPermissionConfig(AUTH.SEMANTIC_CATEGORY.DISABLE)}
                      onClick={() => {
                        modal.confirm({
                          title: dict('CONFIRM_DISABLE_KEYWORD_TIP', { name: record.name }),
                          content: dict('CONFIRM_DISABLE_KEYWORD_SUB_TIP'),
                          onOk: async () => {
                            try {
                              setLoading();
                              await updateKeywordCategoryStatus(record.id, true);
                              message.success(dict('OPERATION_SUCCESS'));
                              refresh();
                            } catch (error) {
                              message.error(get(error, 'status.message', dict('OPERATION_FAILURE')));
                            }
                            hideLoading();
                          },
                        });
                      }}
                    >
                      {dict('ACTION_DISABLE')}
                    </Link>
                  ) : (
                    <Link
                      {...getBtnPermissionConfig(AUTH.SEMANTIC_CATEGORY.DISABLE)}
                      onClick={async () => {
                        modal.confirm({
                          title: dict('CONFIRM_ENABLE_KEYWORD_TIP', { name: record.name }),
                          content: dict('CONFIRM_ENABLE_KEYWORD_SUB_TIP'),
                          onOk: async () => {
                            try {
                              setLoading();
                              await updateKeywordCategoryStatus(record.id, false);
                              message.success(dict('OPERATION_SUCCESS'));
                              refresh();
                            } catch (error) {
                              message.error(get(error, 'status.message', dict('OPERATION_FAILURE')));
                            }
                            hideLoading();
                          },
                        });
                      }}
                    >
                      {dict('ACTION_ENABLE')}
                    </Link>
                  )}
                  <Link
                    {...getBtnPermissionConfig(AUTH.SEMANTIC_CATEGORY.DELETE)}
                    onClick={() => {
                      modal.confirm({
                        title: dict('CONFIRM_DELETE_KEYWORD_TIP', { name: record.name }),
                        content: dict('CONFIRM_DELETE_KEYWORD_SUB_TIP'),
                        onOk: async () => {
                          try {
                            setLoading();
                            await deleteKeywordCategory(record.id);
                            message.success(dict('DELETE_SUCCESS'));
                            event$.emit('refreshCount');
                            refresh();
                          } catch (error) {
                            message.error(get(error, 'status.message', dict('DELETE_FAILURE')));
                          }
                          hideLoading();
                        },
                      });
                    }}
                  >
                    {dict('ACTION_DELETE')}
                  </Link>
                </Space>
              );
            },
          }),
        ]}
      />
    </>
  );
};

export default List;
