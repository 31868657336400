import { Blank } from '@/components/PageCard';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { Badge, Button, Table, Typography } from '@antd';
import { Space } from '@antd';
import React, { useState } from 'react';
import { Func1 } from 'redux';
import { useUserPickModal } from '../modal/useUsePickerModal';
import { useRequest } from 'ahooks';
import { getUserList } from '@/services/user';
import type { UserDetail } from '@/services/user/type';
import { get, isEmpty } from 'lodash';
import { ArrLabelText } from '@/components/Form/Field/Text';
import { UserStatusEnum, userStatusOptions } from '../../const.var';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import type { ColumnType } from 'rc-table';
import type { TableRowSelection } from 'antd/es/table/interface';

type UserConfigField = {
  value?: Key[];
  onChange?: Func1<Key[] | undefined, void>;
  id?: string;
  readonly?: boolean;
};
export default ({ value, onChange, readonly, ...props }: UserConfigField) => {
  const { user } = useAppContext();
  const [UserPickerModal, openPickModal] = useUserPickModal();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const { data: allUsers, loading } = useRequest(() => getUserList(user?.bid), { refreshDeps: [user] });

  const { tableProps } = useTableRequest(
    async ({ filters }) => {
      let list: UserDetail[] = [];
      if (!isEmpty(value) && !isEmpty(allUsers)) {
        list = allUsers?.filter((v) => value?.includes(v.id)) || [];
      }

      if (filters && filters.enableStatus) {
        const enableStatus = get(filters.enableStatus, '[0]');
        list = list?.filter((v) => v.enableStatus == enableStatus);
      }
      return { list };
    },
    { refreshDeps: [value, allUsers] },
  );

  const delUser = (keys: Key[]) => {
    onChange?.(value?.filter((v) => !keys.includes(v)));
    setSelectedRowKeys([]);
  };

  return (
    <div id={props.id}>
      {!readonly && (
        <>
          <Space>
            <Button type="primary" size="small" onClick={() => openPickModal(value || [])}>
              {/* 添加用户 */}
              {dict('ACTION_ADD_USER')}
            </Button>

            <Button
              disabled={isEmpty(selectedRowKeys)}
              type="link"
              size="small"
              onClick={() => delUser(selectedRowKeys)}
            >
              {/* 批量移除 */}
              {dict('ACTION_BATCH_REMOVE')}
            </Button>
          </Space>
          <Blank />
        </>
      )}
      <Table
        bordered
        {...tableProps}
        loading={loading}
        rowSelection={
          readonly
            ? undefined
            : ({
                selectedRowKeys: selectedRowKeys,
                onChange: setSelectedRowKeys,
              } as TableRowSelection<Key>)
        }
        size="small"
        columns={
          [
            getTableColumn({
              title: dict('USERNAME'),
              dataIndex: 'username',
            }),
            getTableColumn({
              title: dict('STATUS'),
              align: 'center',
              dataIndex: 'enableStatus',
              filters: userStatusOptions.map(({ label, value }) => ({ text: label, value })),
              filterMultiple: false,
              render: (v) => {
                const status: number = v || UserStatusEnum.DISABLE;
                return (
                  <Badge
                    status={status ? 'success' : 'default'}
                    text={<ArrLabelText list={userStatusOptions} value={`${status}`} />}
                  />
                );
              },
            }),
            readonly
              ? false
              : getTableColumn({
                  title: dict('ACTION_OPERATE'),
                  align: 'center',
                  dataIndex: 'id',
                  render: (v) => (
                    <Typography.Link onClick={() => delUser([v])}>{dict('ACTION_DELETE')}</Typography.Link>
                  ),
                }),
          ].filter(Boolean) as ColumnType<AnyObject>[]
        }
      />
      <UserPickerModal users={allUsers} onUserSelect={onChange} />
    </div>
  );
};
