import { Descriptions } from '@antd';
import type { ConfigPrivilegeDetail } from '@/services/user_team/type';
import { DateUtils } from '@/utils/dateUtil';
import { dict } from '@/hooks/useChangeLocale';

export const PageTop: React.FC<{ group?: ConfigPrivilegeDetail }> = ({ group }) => {
  return (
    <Descriptions
      title={group?.teamName}
      items={[
        {
          label: dict('GROUP_USERS'),
          children: `${group?.userCount || 0}${dict('STATIST_UNIT_REN')}`,
        },
        {
          label: dict('CREATE_TIME'),
          children: DateUtils.formatDateTime(group?.createdAt),
        },
        {
          label: dict('CREATOR'),
          children: group?.createdUserName,
        },
        {
          label: dict('REMARK'),
          children: group?.remark || '-',
        },
      ]}
    />
  );
};
