import useCardSelect from '../useCardSelect';
import type { CardItem } from '../useCardSelect';
import ContentChart from '../charts/ContentChart';
import { Format } from '@/utils/format';
import { ProCard } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import { getHomeLiveOverview, getHomeLiveTrend } from '@/services/home';
import { isNil } from 'lodash';
import { dict } from '@/hooks/useChangeLocale';
import { HomeDataRequest } from '@/services/home/type';

const Summary = ({ searchParams }: { searchParams: HomeDataRequest }) => {
  const [CardSelect, cardSelectedIndex] = useCardSelect();
  const { data, loading } = useRequest(
    async () => {
      if (isNil(searchParams.bid) || isNil(searchParams.tenantId)) return undefined;
      return Promise.all([getHomeLiveOverview(searchParams), getHomeLiveTrend(searchParams)]);
    },
    {
      debounceWait: 200,
      refreshDeps: [searchParams],
    },
  );

  // 解构数据
  const [overviewData, tendencyAnalysisData] = data || [];

  const items: CardItem[] = [
    {
      type: 'line',
      title: dict('STAY_ANALYSIS'),
      child: [
        {
          title: dict('AVERAGE_STAY_DURATION'),
          value: Format.toAmountNumber(overviewData?.durationAvg),
          key: 'liveDurationAvg',
        },
      ],
    },
    {
      type: 'line',
      title: dict('INTERACTIVE_ANALYSIS'),
      child: [
        {
          title: dict('LIKE_CLICK'),
          value: Format.toAmountNumber(overviewData?.likeSum),
          key: 'lpScreenLiveLikeCount',
        },
        {
          title: dict('COMMENT'),
          value: Format.toAmountNumber(overviewData?.commentSum),
          key: 'lpScreenLiveCommentCount',
        },
        {
          title: dict('SHARE'),
          value: Format.toAmountNumber(overviewData?.shareSum),
          key: 'lpScreenLiveShareCount',
        },
      ],
    },
    {
      type: 'line',
      title: dict('RISING_FOLLOWERS_ANALYSIS'),
      child: [
        {
          title: dict('NEW_FUNS_COUNT'),
          value: Format.toAmountNumber(overviewData?.newFansCount),
          key: 'liveFollowCount',
        },
      ],
    },
    {
      type: 'line',
      title: dict('RETENTION_ANALYSIS'),
      child: [
        {
          title: dict('RETNETION_AMOUNT'),
          value: Format.toAmountNumber(overviewData?.cluesCount),
          key: 'liveClueCount',
        },
      ],
    },
  ];

  const selectedItem = items[cardSelectedIndex || 0];
  const lineChartFieldMap = selectedItem.child.reduce((prev, current) => {
    prev[current.key] = current.title;
    return prev;
  }, {});
  // 为tendencyAnalysisData增加date
  const disposedTendencyAnalysisData = (tendencyAnalysisData || []).map((item) => ({
    ...item,
    date: item.liveDate,
  }));

  return (
    <>
      <ProCard.Group direction="row">
        <CardSelect items={items} columns={4} loading={loading} />
        <div style={{ padding: '20px 0' }}>
          <ContentChart
            data={disposedTendencyAnalysisData}
            fieldName={selectedItem.child[0].key}
            fieldMap={lineChartFieldMap}
            type={selectedItem.type}
            loading={loading}
          />
        </div>
      </ProCard.Group>
    </>
  );
};

export default Summary;
