import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { Function0, Function1 } from 'lodash';

export type ManualRuleProps = {
  onChange?: (value: AnyObject[]) => void;
  readonly?: boolean;
  value?: AnyObject[];
};

export type Rule = {
  type: string;
  op: string;
  value: string;
};

export type AutoRuleValue = {
  id: Key;
  valueName: string;
  rule: AutoRuleConfigItem[][];
};

/**
 * @description 自动打标签
 */
export type AutoRuleValueItemProps = {
  value: AutoRuleValue;
  allValue: AutoRuleValue[];
  onChange?: (value: AutoRuleValue) => void;
  onDelete?: (id: Key) => void;
  readonly?: boolean;
  checked?: boolean;
  onChecked?: (id: Key) => void;
  event$: EventEmitter<EmitterObject>;
};

export type AutoRuleProps = {
  onChange?: (value: AnyObject[]) => void;
  readonly?: boolean;
  value?: AnyObject[];
  tagObject: string;
};

export type Option = { label: string; value: string };

export type ManualRuleItem = {
  id: Key;
  valueName: string;
};

export type ManualRuleInputProps = {
  readonly?: boolean;
  item: ManualRuleItem;
  configRules: ManualRuleItem[];
  onChange?: Function1<ManualRuleItem, void>;
  onRemove?: Function0<void>;
  event$: EventEmitter<EmitterObject>;
};

export enum AutoRuleOpEnum {
  IN = 'in',
  EQ = 'eq',
  GT = 'gt',
  LT = 'lt',
  GE = 'ge',
  LE = 'le',
  MULTIPLE = 'multiple',
  SINGLE = 'single',
}

export enum AutoRuleValueEnum {
  STRING = 'STRING',
  ENUM = 'ENUM',
  INTEGER = 'INTEGER',
}

export type AutoRuleConfigDrawerProps = {
  ruleSet?: AutoRuleConfigItem[];
  onChange?: Function1<AutoRuleConfigItem[], void>;
  readonly?: boolean;
  visible: boolean;
  hideDrawer: Function0<void>;
  ruleObjOptions: Option[]; //授权对象
  ruleObjeMapping: AnyObject;
};

export type AutoRuleConfigItem = {
  id?: string;
  key?: string;
  opt?: string;
  value?: Key | Key[];
};

export type AutoRoleConfigItemRowProps = {
  value: AutoRuleConfigItem;
  onDelete: Function1<string, void>;
  onChange?: Function1<AnyObject, void>;
  ruleObjOptions: Option[]; //授权对象
  ruleObjeMapping: AnyObject;
};
