import ChartCore from '@/components/ChartCore';
import { headerFormCardProps } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';
import { getTagAnalysis } from '@/services/tag/indext';
import { Format } from '@/utils/format';
import { ProCard } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import { Key } from 'react';

const Chart = ({ id }: { id?: Key }) => {
  const { data, loading } = useRequest(() => getTagAnalysis(id), { refreshDeps: [id] });

  const { total, overlayCount, tagValueMap } = data || {};

  const option = {
    title: {
      text: `${dict('COVER_RATE')}：${Format.toPercent((overlayCount || 0) / (total || 1))}        ${dict('COVER_COUNT')}：${overlayCount}/${total}`,
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      top: 50,
    },
    xAxis: [
      {
        type: 'category',
        data: Object.keys(tagValueMap || {}),
        axisLabel: {
          margin: 10,
        },
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [
      {
        type: 'bar',
        barMaxWidth: 50,
        data: Object.values(tagValueMap || {}),
      },
    ],
  };

  return (
    <ProCard loading={loading} {...headerFormCardProps} title={dict('TAG_ANALYLSIS')}>
      <ChartCore option={option} notMerge style={{ height: 300 }} />
    </ProCard>
  );
};

export default Chart;
