import { ROUTE_PATH } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { getUserTeamPages } from '@/services/user_team';
import { DateUtils } from '@/utils/dateUtil';
import { Table, Typography } from '@antd';
import { Space } from '@antd';

import { useNavigate } from 'umi';

export const UserGroupList = ({ roleId }: { roleId: Key }) => {
  const { user } = useAppContext();

  const navigate = useNavigate();
  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize }) => getUserTeamPages({ roleId, pageIndex, pageSize, bid: user?.bid }),
    { refreshDeps: [roleId, user] },
  );

  return (
    <Table
      {...tableProps}
      size="small"
      bordered
      columns={[
        getTableColumn({ title: dict('USERGROUP_NAME'), dataIndex: 'teamName' }),
        getTableColumn({ title: dict('USER_COUNT'), dataIndex: 'userCount', align: 'center' }),
        getTableColumn({
          title: dict('CREATE_TIME'),
          dataIndex: 'createdAt',
          align: 'center',
          render: (v) => DateUtils.formatDateTime(v),
        }),
        getTableColumn({ title: dict('CREATOR'), dataIndex: 'createdUserName', align: 'center' }),
        getTableColumn({
          title: dict('ACTION_OPERATE'),
          align: 'center',
          render: (_, record) => {
            return (
              <Space>
                <Typography.Link
                  onClick={() => navigate(`../${ROUTE_PATH.USER_GROUP}/${ROUTE_PATH.DETAIL}/${record.id}`)}
                >
                  {dict('ACTION_VIEW')}
                </Typography.Link>
              </Space>
            );
          },
        }),
      ]}
    />
  );
};
