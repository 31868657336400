import { useMemoizedFn, useSafeState } from 'ahooks';
import { Select } from '@antd';
import { dict } from '@/hooks/useChangeLocale';

// 趋势类型枚举
export enum TrendType {
  // 直播场次
  LIVE_SESSION = 'liveCount',
  // 视频量
  VIDEO_COUNT = 'publishCount',
  // 粉丝量
  FANS_COUNT = 'fansTotal',
  // 线索量
  CLUE_COUNT = 'totalClueCount',
}

export const trendTypeOptions = [
  {
    label: dict('LIVE_TOTAL'),
    value: TrendType.LIVE_SESSION,
  },
  {
    label: dict('VIDEO_TOTAL'),
    value: TrendType.VIDEO_COUNT,
  },
  {
    label: dict('FANS_TOTAL'),
    value: TrendType.FANS_COUNT,
  },
  {
    label: dict('CLUE_TOTAL'),
    value: TrendType.CLUE_COUNT,
  },
];

export default (): [React.FC, TrendType] => {
  const [type, setType] = useSafeState(TrendType.CLUE_COUNT);

  const TypeSelect = useMemoizedFn((props) => {
    return (
      <Select
        style={{ width: 120 }}
        value={type}
        options={trendTypeOptions}
        onChange={(e) => {
          setType(e as TrendType);
        }}
        {...props}
      />
    );
  });

  return [TypeSelect, type];
};
