import { useAppContext } from '@/contexts/AppContext';
import { getOrganizationList } from '@/services/organization';
import { Menu, type MenuProps } from '@antd';
import { useCreation, useRequest } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { get } from 'lodash';
import React from 'react';
import { EventType } from '../..';
import { OrganizationEnum, OrganizationTypes, getMenuCount } from '../../const.var';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
  selectable?: boolean,
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    selectable,
  } as MenuItem;
}

type LeftNavProp = {
  orgType: OrganizationEnum;
  onOrgTypeChange: (v: OrganizationEnum) => void;
  event$: EventEmitter<EventType>;
};

const LeftNav = ({ orgType, onOrgTypeChange, event$ }: LeftNavProp) => {
  const { user } = useAppContext();
  const { data: organizations, refresh } = useRequest(() => getOrganizationList(user?.bid), { refreshDeps: [user] });

  const items: MenuProps['items'] = useCreation(() => {
    const orgCount = organizations?.reduce((map, cur) => {
      const type = cur.type;
      map[type] = get(map, `${type}`, 0) + 1;
      return map;
    }, {});
    return OrganizationTypes.map((v) => getItem(getMenuCount(v.label, get(orgCount, v.value)), v.value));
  }, [organizations]);

  event$.useSubscription(({ action }) => {
    if (action == 'refreshOrg') refresh();
  });

  return (
    <Menu
      style={{ width: 200 }}
      className="page-left-nav"
      selectedKeys={[`${orgType}`]}
      onClick={(e) => {
        onOrgTypeChange(Number(e.key) as OrganizationEnum);
      }}
      items={items}
    />
  );
};

export default LeftNav;
