import Text from '@/components/Form/Field/Text';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { getAuthUrl } from '@/services/auth';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { ModalForm } from '@ant-design/pro-components';
import { App, Form } from '@antd';
import { useAsyncEffect, useSafeState } from 'ahooks';
import React from 'react';

type FormModalProps = { onCopy?: (value) => Promise<AnyObject>; record?: AnyObject; children: AnyObject };
type FormType = {
  code: string;
  validity: string;
};
const FormModal: React.FC<FormModalProps> = ({ children, onCopy, ...props }) => {
  const [form] = Form.useForm<FormType>();
  const [opened, setOpened] = useSafeState(false);
  const { user } = useAppContext();
  const { message } = App.useApp();

  useAsyncEffect(async () => {
    if (!opened) return;
    const result = await getAuthUrl(user?.bid);
    const url = new URL(result);
    url.hostname = location.hostname;
    form.setFieldValue('link', decodeURIComponent(url.href));
  }, [user, opened]);

  return (
    <ModalForm<FormType>
      title={dict('DOWNLOAD_AUTH_LINK')}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 22 }}
      width={600}
      trigger={children}
      form={form}
      onOpenChange={setOpened}
      layout="horizontal"
      modalProps={{ okText: dict('COPY_AUTHORIZATION_INFO'), cancelText: dict('CLOSE'), destroyOnClose: true }}
      onFinish={async (values) => {
        try {
          await copyToClipboard(values.link);
          message.success(dict('COPY_TEXT_TO_CLIPBOARD'));
          return true;
        } catch (error) {
          message.error(dict('COPY_TEXT_FAILURE'));
        }
      }}
      {...props}
    >
      <Form.Item label={dict('AUTH_LINK')} name="link">
        <Text />
      </Form.Item>
    </ModalForm>
  );
};

export default FormModal;
