import { getTableColumn } from '@/hooks/useTableRequest';
import { getAllPrivilege } from '@/services/privilege';
import type { PrivilegeData } from '@/services/privilege/type';
import { Checkbox, Flex, Table } from '@antd';
import { useCreation, useEventEmitter, useRequest } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { get, intersection, isEmpty } from 'lodash';
import React, { Key } from 'react';
import { Func1 } from 'redux';
import style from './style.less';
import { dict } from '@/hooks/useChangeLocale';

type PermissionField = {
  value?: Key[];
  onChange?: Func1<AnyObject, void>;
  id?: string;
  readonly?: boolean;
};

export default ({ value, onChange, id, readonly }: PermissionField) => {
  const event$ = useEventEmitter<{ groupKeys: string[]; checked: boolean }>();

  const { data = [] } = useRequest(async () => {
    const privilegeData = await getAllPrivilege();
    return convertDataSource(privilegeData) as [RunPermissionNode[], string[], AnyObject];
  });
  const [dataSource, allKeys, idKeyMap] = data;

  const checkedKeys = useCreation(() => (value || []).map((v) => get(idKeyMap, `${v}`, '')), [value, idKeyMap]);

  event$.useSubscription((val) => {
    const { groupKeys, checked } = val;
    const newValueSet = new Set(checkedKeys);
    groupKeys.forEach((key) => {
      if (checked) {
        newValueSet.add(key);
      } else {
        newValueSet.delete(key);
      }
    });
    onChange?.(Array.from(newValueSet).map((v) => idKeyMap[v]));
  });

  const moduleRowCount = useCreation(
    () =>
      dataSource?.reduce((map, row, index) => {
        const { moduleKey } = row;
        if (map[moduleKey]) {
          map[moduleKey] = map[moduleKey] + 1;
        } else {
          map[moduleKey] = 1;
          map[`${moduleKey}_${index}`] = 'rowSpan';
        }
        return map;
      }, {}),
    [dataSource],
  );

  return (
    <div id={id}>
      <Table
        bordered
        rowKey={(v) => JSON.stringify(v)}
        size="small"
        pagination={false}
        dataSource={dataSource || []}
        columns={[
          getTableColumn({
            title: dict('MODULE'),
            width: '18%',
            align: 'center',
            onCell: (record, index) => {
              const { moduleKey } = record;
              return {
                rowSpan: moduleRowCount?.[`${moduleKey}_${index}`] == 'rowSpan' ? moduleRowCount[record.moduleKey] : 0,
              };
            },
            render: (_, record) => (
              <CheckPremission
                permissionKey={record.moduleKey}
                name={record.moduleName}
                event={event$}
                allKeys={allKeys as string[]}
                checkedKeys={checkedKeys}
                readonly={readonly}
              />
            ),
          }),
          getTableColumn({
            title: dict('FUNCTION_PERMISSION'),
            width: '18%',
            align: 'center',
            render: (_, record) => (
              <CheckPremission
                permissionKey={record.menuKey}
                name={record.menuName}
                event={event$}
                allKeys={allKeys as string[]}
                checkedKeys={checkedKeys}
                readonly={readonly}
              />
            ),
          }),
          getTableColumn({
            title: dict('OPERATION_PERMISSION'),
            align: 'center',
            render: (_, record) => (
              <Flex gap={10} justify="flex-start" wrap="wrap">
                {record.operations.map((operation) => (
                  <CheckPremission
                    key={operation.key}
                    permissionKey={operation.key}
                    name={operation.name}
                    event={event$}
                    checkedKeys={checkedKeys}
                    allKeys={allKeys as string[]}
                    readonly={readonly}
                  />
                ))}
              </Flex>
            ),
          }),
        ]}
      />
    </div>
  );
};

type CheckPremissionProps = {
  permissionKey: string;
  name: string;
  event: EventEmitter<{ groupKeys: string[]; checked: boolean }>;
  checkedKeys: string[];
  allKeys: string[];
  readonly?: boolean;
};

type RunPermissionNode = {
  menuKey: string;
  menuName: string;
  moduleKey: string;
  moduleName: string;
  operations: Key[];
  id: number;
};
const CheckPremission: React.FC<CheckPremissionProps> = ({
  permissionKey,
  name,
  event,
  checkedKeys,
  allKeys,
  readonly,
}) => {
  const groupKeys = useCreation(
    () => allKeys.filter((key) => key.startsWith(`${permissionKey}.`) || key == permissionKey),
    [permissionKey, allKeys],
  );
  const groupCheckedKeys = intersection(checkedKeys, groupKeys);
  const isAllChecked = groupKeys.length == groupCheckedKeys.length;
  const isNonChecked = isEmpty(groupCheckedKeys);
  return (
    <Checkbox
      checked={isAllChecked}
      style={{ minWidth: 110 }}
      indeterminate={!(isAllChecked || isNonChecked)}
      rootClassName={readonly ? style['readonly-checkbox'] : undefined}
      onChange={(e) => {
        if (readonly) return;
        event.emit({ groupKeys, checked: e.target.checked });
      }}
    >
      {name}
    </Checkbox>
  );
};

const convertDataSource = (datas: PrivilegeData[]) => {
  const [MODULE_KEY, MENU_KEY, OPERATION_KEY] = ['module', 'function', 'operation'];
  const dataDict = {}; //对象临时索引
  const objCache = {}; //转换Antd对象临时缓存
  const idKeyMap = {};
  const keys: string[] = []; //所有键值集合

  const typeMap = { 3: MODULE_KEY, 4: MENU_KEY, 5: OPERATION_KEY };

  //计算排序权重
  const getSort = (() => {
    //键值缓存
    const keySortMap = datas.reduce((map, { name, seq }) => {
      const length = name.split('.').length;
      map[name] = length <= 2 ? 0 : seq * Math.pow(10, 2 * (5 - length));
      console.log(name, seq);
      return map;
    }, {});

    // console.log(keySortMap);

    return (key: string) => {
      let sortVal = 0;
      while (keySortMap[key]) {
        sortVal += keySortMap[key];
        const arr = key?.split('.');
        arr.pop();
        key = arr.join('.');
      }
      return sortVal;
    };
  })();

  const dataSource = datas
    .map((v) => {
      const { name, parentName, displayName, id } = v;
      const type = typeMap[name.split('.').length];
      const data = {
        key: name,
        parentKey: parentName,
        name: displayName,
        type,
        sort: getSort(name),
        id,
      };
      console.log(name, data.sort);
      dataDict[name] = data;
      idKeyMap[id] = name;
      idKeyMap[name] = id;
      if (type == OPERATION_KEY) keys.push(name);
      return data;
    })
    .sort((v1, v2) => v1.sort - v2.sort) //模块排序;
    .reduce((arr, data) => {
      const { key, name, parentKey, id } = data; //处理成表数据，meun为行数据
      if (data.type == MENU_KEY) {
        const parent = dataDict[parentKey];
        const row = {
          menuKey: key,
          menuName: name,
          moduleKey: parent.key,
          moduleName: parent.name,
          operations: [],
          id,
        };
        objCache[data.key] = row;
        arr.push(row);
      } else if (data.type == OPERATION_KEY) {
        objCache[parentKey].operations.unshift({ key, name });
      }
      return arr;
    }, [] as RunPermissionNode[]);

  return [dataSource, keys, idKeyMap];
};
