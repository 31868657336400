import ChartCore from '@/components/ChartCore';
import { Spin } from '@antd';
import { useRequest } from 'ahooks';
import { getTrendLineChatOption } from './chart.confg';
import { TrendType, trendTypeOptions } from '@/pages/workbench/outline/components/useTrendTypeSelect';
import Color from 'color';
import { themeConfig } from '@/layouts/const/themes';
import { getHomeTendencyAnalysis } from '@/services/home';
import { isNil } from 'lodash';
import { HomeDataRequest } from '@/services/home/type';

const LineChart = ({ searchParams, trendType }: { searchParams: HomeDataRequest; trendType: TrendType }) => {
  const { data = {}, loading } = useRequest(
    async () => {
      if (isNil(searchParams.bid) || isNil(searchParams.tenantId)) return undefined;
      const list = await getHomeTendencyAnalysis({
        ...searchParams,
      });
      return list.reduce((obj, cur) => {
        obj[cur.settledDate] = cur;
        return obj;
      }, {});
    },
    {
      refreshDeps: [searchParams],
      debounceWait: 200,
    },
  );
  const color = {
    [TrendType.LIVE_SESSION]: Color(themeConfig.colorPrimary).alpha(0.95).lighten(0.2).hex(),
    [TrendType.VIDEO_COUNT]: '#FFBE3D',
    [TrendType.FANS_COUNT]: '#2DCCA6',
    [TrendType.CLUE_COUNT]: '#B257D9',
  }[trendType];

  return (
    <Spin spinning={loading}>
      <ChartCore
        option={getTrendLineChatOption(
          Object.values(data),
          Object.keys(data),
          trendTypeOptions
            .filter((item) => item.value === trendType)
            .reduce((prev, current) => {
              prev[current.value] = current.label;
              return prev;
            }, {}),
          false,
          [color],
        )}
        notMerge
        style={{ height: 380 }}
      />
    </Spin>
  );
};

export default LineChart;
