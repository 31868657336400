import { ArrLabelText } from '@/components/Form/Field/Text';
import { dict } from '@/hooks/useChangeLocale';
import { Card, Descriptions, Spin } from '@antd';
import { TagTypeOptions, TagValueTypeOptions } from '../../const.var';
import { TagModel } from '@/services/tag/type';

type BaseInfoProps = {
  detail?: TagModel;
};
export const BasicInfo = ({ detail }: BaseInfoProps) => {
  return (
    <Spin spinning={!detail}>
      <Card bordered={false}>
        <Descriptions column={4} title={detail?.name}>
          <Descriptions.Item label={dict('TAG_TYPE')}>
            <ArrLabelText list={TagTypeOptions} value={detail?.type} />
          </Descriptions.Item>
          <Descriptions.Item label={dict('TAG_VALUE_TYPE')}>
            <ArrLabelText list={TagValueTypeOptions} value={detail?.valType} />
          </Descriptions.Item>
          <Descriptions.Item label={dict('CREATE_TIME')}>{detail?.createdAt}</Descriptions.Item>
          <Descriptions.Item label={dict('REMARK')}>
            <div className="ellipsis-3-line" title={detail?.remark}>
              {detail?.remark}
            </div>
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </Spin>
  );
};
