import Text, { ArrLabelText } from '@/components/Form/Field/Text';
import { Blank, FormCard } from '@/components/PageCard';
import { defalutFormInputStyle, defaultFormItemConfig } from '@/constants/const';
import { FormOpType } from '@/constants/enum';
import { App, Button, Form, Input, Radio, Space } from '@antd';
import React, { CSSProperties } from 'react';
import { dict } from '@/hooks/useChangeLocale';
import {
  TagObjectOptions,
  TagType,
  TagTypeOptions,
  TagValueTypeOptions,
  removeRuleConfigTempId,
  valitorTagConfig,
} from '../../const.var';
import { AutoRule, ManualRule, getAutoRuleTagValue, getManualRuleValue } from '../RuleConfig';
import { get } from 'lodash';
import { checkName } from '@/services/tag/indext';
import { useBoolean } from 'ahooks';
import { REGEX } from '@/constants/regex';

type FormFieldsType = {
  initValue?: AnyObject;
  type: FormOpType;
  onFormSubmit?: (values) => void;
  id?: Key;
  back?: VoidFunction;
  style?: CSSProperties | undefined;
};

const FormFields: React.FC<FormFieldsType> = ({ initValue, type, onFormSubmit, back, style }) => {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const tagType = Form.useWatch('type', form);
  const tagObject = Form.useWatch('model', form);
  const isView = type == FormOpType.VIEW;
  const isCreate = type == FormOpType.CREATE;
  const [submiting, { setTrue: setSubmiting, setFalse: setSubmited }] = useBoolean(false);

  const submit = async () => {
    setSubmiting();
    try {
      const { ruleConfig, ...fields } = await form.validateFields();
      await onFormSubmit?.({
        ...fields,
        ruleConfig: removeRuleConfigTempId(ruleConfig),
      });
    } catch (error) {
      if (get(error, 'errorFields')) {
        form.scrollToField(get(error, 'errorFields[0].name[0]', ''));
      } else {
        message.error(get(error, 'status.message', dict('OPERATION_FAILURE')));
      }
    } finally {
      setSubmited();
    }
  };

  return (
    <>
      <Form
        {...defaultFormItemConfig}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        form={form}
        initialValues={initValue}
        validateTrigger={['onSubmit', 'onChange', 'onBlur']}
        requiredMark={!isView}
        style={style}
      >
        <Form.Item name="bid" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <FormCard title={dict('BASIC_INFO')}>
          <Form.Item
            label={dict('TAG_NAME')}
            name="name"
            rules={[
              {
                required: true,
                max: 50,
              },
              {
                pattern: REGEX.NO_BLANK_CHAR,
                message: dict('COUNT_CN_EN_NUMBER_UNLINE_SYMBOL_TEXT_REQUIRD_TIP', { count: 50 }),
              },
              {
                validateTrigger: 'onBlur',
                async validator(_, name) {
                  try {
                    const exist = await checkName(name, initValue.bid, initValue.id);
                    if (exist) return Promise.reject(dict('NAME_EXISTED', { name: dict('TAG_NAME') }));
                  } catch (error) {
                    return Promise.reject(get(error, 'status.message', dict('VALIDATION_EXCEIPTION')));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            {isView ? (
              <Text />
            ) : (
              <Input
                placeholder={dict('COUNT_CN_EN_NUMBER_UNLINE_SYMBOL_TEXT_REQUIRD_TIP', { count: 50 })}
                style={defalutFormInputStyle}
              />
            )}
          </Form.Item>

          <Form.Item label={dict('TAG_OBJECT')} name="model" hidden>
            {isCreate ? (
              <Radio.Group
                options={TagObjectOptions}
                onChange={() => {
                  if (tagType == TagType.AUTO) {
                    form.setFieldsValue({
                      ruleConfig: [getAutoRuleTagValue()],
                    });
                  }
                }}
              />
            ) : (
              <ArrLabelText list={TagObjectOptions} />
            )}
          </Form.Item>

          <Form.Item label={dict('TAG_TYPE')} name="type">
            {isCreate ? (
              <Radio.Group
                options={TagTypeOptions}
                onChange={(e) =>
                  form.setFieldsValue({
                    ruleConfig: [e.target.value == TagType.MANUAL ? getManualRuleValue() : getAutoRuleTagValue()],
                  })
                }
              />
            ) : (
              <ArrLabelText list={TagTypeOptions} />
            )}
          </Form.Item>

          <Form.Item label={dict('TAG_VALUE_TYPE')} name="valType">
            {/* 单值/多值 */}
            {isCreate ? <Radio.Group options={TagValueTypeOptions} /> : <ArrLabelText list={TagValueTypeOptions} />}
          </Form.Item>

          <Form.Item label={dict('REMARK')} name="remark" rules={[{ max: 255 }]}>
            {isView ? (
              <Text />
            ) : (
              <Input.TextArea
                maxLength={255}
                placeholder={dict('PLEASE_TYPE')}
                showCount
                style={defalutFormInputStyle}
              />
            )}
          </Form.Item>
        </FormCard>
        <Blank />

        <FormCard title={dict('RULE_CONFIG')}>
          {/* 标签规则 */}
          {tagType != TagType.AUTO ? (
            <Form.Item
              label={dict('TAG_VALUE')} //标签值
              name="ruleConfig"
              required
              rules={[
                {
                  required: true,
                  validateTrigger: 'onSubmit',
                  validator: valitorTagConfig,
                },
              ]}
            >
              <ManualRule readonly={isView} />
            </Form.Item>
          ) : (
            <Form.Item
              label={dict('AUTO_RULE')}
              name="ruleConfig"
              required
              rules={[
                {
                  required: true,
                  validateTrigger: 'onSubmit',
                  validator: valitorTagConfig,
                },
              ]}
            >
              <AutoRule tagObject={tagObject} readonly={isView} />
            </Form.Item>
          )}
        </FormCard>

        {type != FormOpType.VIEW && (
          <>
            <Blank />
            <FormCard>
              <Space style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="primary" loading={submiting} onClick={submit}>
                  {dict('ACTION_SUBMIT')}
                </Button>
                <Button onClick={back}>{dict('ACTION_CANCEL')}</Button>
              </Space>
            </FormCard>
          </>
        )}
      </Form>
    </>
  );
};

export default FormFields;
