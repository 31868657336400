import ChartCore from '@/components/ChartCore';
import { DateUtils } from '@/utils/dateUtil';
import { Spin } from '@antd';
import { getTrendLineChatOption } from './chart.confg';
import { chortColors } from '@/components/ChartCore/theme';

type DataItem = {
  date: string;
  [key: string]: unknown;
};

const getOptions = ({ data, fieldMap, fieldName, type }) => {
  const dataSort = data
    .sort((v1, v2) => DateUtils.toTimestamp(v1.date) - DateUtils.toTimestamp(v2.date))
    .reduce((obj, cur) => {
      obj[cur.date] = type === 'bar' ? cur[fieldName] : cur;
      return obj;
    }, {});
  if (type === 'bar')
    return {
      tooltip: {
        trigger: 'axis',
        formatter: '{b}：{c}',
      },
      legend: {},
      grid: {
        top: 20,
        left: 30,
        right: 30,
        bottom: 60,
      },
      xAxis: {
        type: 'category',
        data: Object.keys(dataSort || {}),
        offset: 10,
      },
      yAxis: {
        type: 'value',
      },
      dataZoom:
        data.length > 10
          ? [
              { show: true, maxValueSpan: 20, minValueSpan: 15, start: 90 },
              { type: 'inside', maxValueSpan: 20, minValueSpan: 15 },
            ]
          : undefined,
      series: [
        {
          data: Object.values(dataSort || {}).map((value, index) => ({
            value,
            itemStyle: {
              color: chortColors[index % chortColors.length],
            },
          })),
          type: 'bar',
        },
      ],
    };

  if (type === 'biaxLine')
    return {
      tooltip: {
        trigger: 'axis',
      },
      // color,
      legend: false,
      grid: [
        {
          top: '4%',
          left: 100,
          right: 50,
          height: '38%',
        },
        {
          left: 100,
          right: 50,
          top: '48%',
          height: '38%',
        },
      ],
      axisPointer: {
        link: [
          {
            xAxisIndex: 'all',
          },
        ],
      },
      dataZoom:
        data.length > 10
          ? [
              { show: true, xAxisIndex: [0, 1], maxValueSpan: 20, minValueSpan: 15, start: 90 },
              { type: 'inside', xAxisIndex: [0, 1], maxValueSpan: 20, minValueSpan: 15 },
            ]
          : undefined,
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          axisLine: { onZero: true },
          axisLabel: { show: false },
          offset: 10,
          data: Object.keys(dataSort),
        },
        {
          gridIndex: 1,
          type: 'category',
          boundaryGap: false,
          axisLine: { onZero: true },
          data: Object.keys(dataSort),
          offset: 10,
          position: 'top',
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            margin: -10,
            inside: true,
            align: 'right',
          },
        },
        {
          type: 'value',
          inverse: true,
          gridIndex: 1,
          axisLabel: {
            margin: -10,
            inside: true,
            align: 'right',
          },
        },
      ],
      series: Object.entries(fieldMap).map(([filed, name], index) => ({
        name,
        xAxisIndex: index,
        yAxisIndex: index,
        // smooth: true,
        areaStyle: {
          opacity: 0,
        },
        type: 'line',
        data: data.map((v) => v[filed]),
      })),
    };

  return getTrendLineChatOption(Object.values(dataSort), Object.keys(dataSort), fieldMap);
};

const LineChart = ({
  data,
  fieldMap,
  fieldName,
  type,
  loading,
}: {
  data: DataItem[];
  fieldMap: Record<string, string>;
  fieldName: string;
  type: string;
  loading: boolean;
}) => {
  return (
    <Spin spinning={loading}>
      <ChartCore option={getOptions({ data, fieldMap, fieldName, type })} notMerge style={{ height: 400 }} />
    </Spin>
  );
};

export default LineChart;
