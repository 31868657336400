import { StatisticCardPanel } from '@/components/StatisticCard';
import { Format } from '@/utils/format';
import { ProCard } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import { getHomeDataOverview } from '@/services/home';
import { isNil } from 'lodash';
import { dict } from '@/hooks/useChangeLocale';
import { HomeDataRequest } from '@/services/home/type';

const DataSummary = ({ searchParams }: { searchParams: AnyObject }) => {
  const { data, loading } = useRequest(
    async () => {
      if (isNil(searchParams.bid) || isNil(searchParams.tenantId)) return undefined;

      return await getHomeDataOverview({
        ...searchParams,
      } as HomeDataRequest);
    },
    {
      debounceWait: 200,
      refreshDeps: [searchParams],
    },
  );

  const items = [
    {
      title: dict('PLATFORM_ACCOUNT'),
      value: Format.toAmountNumber(data?.accountTotal),
      suffix: dict('STATIST_UNIT_GE'),
    },
    {
      title: dict('LIVT_BROADCAST_TOTAL'),
      value: Format.toAmountNumber(data?.liveTotal),
      suffix: dict('STATIST_UNIT_CHANG'),
    },
    {
      title: dict('VIDEO_TOTAL'),
      value: Format.toAmountNumber(data?.itemTotal),
      suffix: dict('STATIST_UNIT_TIAO'),
    },
    {
      title: dict('FANS_TOTAL'),
      value: Format.toAmountNumber(data?.fansTotal),
    },
    {
      title: dict('CLUE_TOTAL'),
      value: Format.toAmountNumber(data?.cluesTotal),
    },
  ];

  return (
    <>
      <ProCard.Group direction="row">
        <StatisticCardPanel items={items} columns={items.length} loading={loading} />
      </ProCard.Group>
    </>
  );
};

export default DataSummary;
